// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaUser, FaPersonBooth, FaFile, FaPlusSquare, FaPowerOff, FaComment } from 'react-icons/fa';
import { Link, Outlet, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchMessage = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('http://localhost:5000/dashboard', {
            headers: { Authorization: token },
          });
          setMessage(response.data.message);
        } catch (err) {
          console.error('Error fetching message:', err);
        }
      }
    };

    fetchMessage();
  }, []);

  const handleLogout =() => {
    axios.get(`http://localhost:5000/logout`)
    .then(result =>{
      if(result.data.Status){
         navigate('/login')
      }
    })
  }
  return( 

    <React.Fragment>
      
      <div className='container-fluid'>
        <div className='row flex-nowrop'>
          <div className='col-auto col-md-3 col-x1-2 px-sm2 px-0 bg-dark'>
            <div className='d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100'>
              <Link to="/dashboard" className='d-flex align-items-center pb-3 mb-md-3 me-md-auto text-white text-decoration-none '>
                <span className='fs-5 fw-bolder d-none d-sm-inline'>Temesgen Advocate</span></Link> 
              <ul className='nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start'>
                <li className='w-100'>
                  <Link to="/dashboard/user"className='nav nav-link text-white px-0 align-middle'>
                  <FaFile style={{ color: 'white', fontSize: '30px' }}
                  />
                    <span className='ms-2 d-none d-sm-inline'>
                    Dashboard
                    </span></Link>
                </li>
                {/* <li>
                <Link to="/dashboard/user"className='nav nav-link text-white px-0 align-middle'>
                <FaUser style={{ color: 'white', fontSize: '30px' }}/>
                    <span className='ms-2 d-none d-sm-inline'>
                    User
                    </span></Link>
                </li> */}
                <li>
                <Link to="/dashboard/news"className='nav nav-link text-white px-0 align-middle'>
                <FaPlusSquare style={{ color: 'white', fontSize: '30px' }}/>
                    <span className='ms-2 d-none d-sm-inline'>
                    News
                    </span></Link>
                </li>
                <li>
                <Link to="/dashboard/reviews"className='nav nav-link text-white px-0 align-middle'>
                <FaUser style={{ color: 'white', fontSize: '30px' }}/>
                    <span className='ms-2 d-none d-sm-inline'>
                    Review
                    </span></Link>
                </li>
                <li>
                <Link to="/dashboard/contactlist"className='nav nav-link text-white px-0 align-middle'>
                <FaComment style={{ color: 'white', fontSize: '30px' }}/>
                    <span className='ms-2 d-none d-sm-inline'>
                    Contact
                    </span></Link>
                </li>
                <li className='w-100' onClick={handleLogout}>
                <Link className='nav nav-link text-white px-0 align-middle'>
                <FaPowerOff style={{ color: 'white', fontSize: '30px' }}/>
                    <span className='ms-2 d-none d-sm-inline'>
                    Logout
                    </span></Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col p-0 m-0'>
            <div className='p-2 d-flex shadow w-100'>
              <h4 className='float-right'> Welcome  {message ? message : 'Loading...'}</h4>
              {/* <h5>{message ? message : 'Loading...'}</h5>  */}
            </div>
            <Outlet/>
          </div>
          
        </div>
      </div>
</React.Fragment>
  
) ;

};

export default Dashboard;
