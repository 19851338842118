import React, { useEffect, useState } from 'react'
// import ReactPaginate from "react-paginate";
import axios from 'axios'


const Revies = () => {
    
 const [userData, setUserData]= useState([]);
 useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('http://localhost:5000/app/fetch-review');
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching images', error);
      }
    };

    fetchImages();
  }, []);
  return (
       
    <section class="reviews">
        <div class="heading">
            <span>Reviews</span>
            <h3>What our clients say about us</h3>
        </div>
        <div class="clients-reviews">
          
               
                    
                     {/* <table className='d-flex'>
                     <tbody>
                            { */}
                            {
                                
                                userData.map((user) =>(
                                    <div key={user.id}> 
                                        <div className='d-flex'>
                                          <div class="review">
                                         <div class="info">
                                         <img 
                                        //   style={{width:"100px"}} 
                                          src={`data:image/jpeg;base64,${user.data}`} 
                                          alt={user.name} 
                                            />
                                        
                                        <div class="row">
                                    <h1>{user.name}</h1>
                                    <span>{user.job}</span>
                                    </div>
                                    </div>
                                    <p>{user.description} </p>
                                    </div>
                                    </div>
                              </div>
                                ))
                                
                            }
                          
                        {/* </tbody>
                     </table> */}
                   
                        {/* <h1>Andrew Torch</h1>
                        <span>A&G texturies</span> */}
                    
                
              
           
            <div class="review">
                <div class="info">
                    <img src="images/image2.jpg" alt=""/>
                    <div class="row">
                        <h1>Elizabeth williyams</h1>
                        <span>DCP inc</span>
                    </div>
                </div>
                <p>Lorem ipsum dolar sit amet consectetur adipisicing elit.NISI accusmass jagfdjvja
                    asdvabjan
                    asgdgasdhashgdawdhadj czcashgdfgaha dvasbdngabjhaksmanasvanvgabj
                </p>
            </div>
            <div class="review">
                <div class="info">
                    <img src="images/image3.jpg" alt=""/>
                    <div class="row">
                        <h1>Ahmed aad</h1>
                        <span>tuearv adajbh</span>
                    </div>
                </div>
                <p>Lorem ipsum dolar sit amet consectetur adipisicing elit.NISI accusmass jagfdjvja
                    asdvabjan
                    asgdgasdhashgdawdhadj czcashgdfgaha dvasbdngabjhaksmanasvanvgabj
                </p>
            </div>
            </div>
        
    </section>
  )
}

export default Revies