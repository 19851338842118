import React from 'react'
import Navbar from '../Navbar'
import { FaFileImage } from 'react-icons/fa';
import Footer from './Footer'

const Service = () => {
  return (
    <React.Fragment>

      <Navbar/>
      <section className='service-section2'>
        {/* <p>what we do</p> */}
        <h3>Service Sectors </h3>
            <div className='service2'>
                <div className='service-content2'>
                    <div>
                      <img src='images/contract.jpg'/>
                    </div>
                    <div>
                    <h2> Contract</h2>
                    <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/ajency.jpeg'/>
                  </div>
                <div>
                <h2>Agency Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content2'>
                <div> 
                <img src='images/banking.jpeg'/>
                </div>
                <div>
                <h2>Banking and Insurance Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content2'>
                 <div>
                 <img src='images/corporate.jpeg'/>
                 </div>
                 <div>
                <h2> Corporate Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/labor.jpeg'/>
                </div>
                <div>
                <h2>Employment and Labour Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/arbitration.jpeg'/>
                </div>
                <div>
                <h2>Arbitration Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/family.png'/>
                </div>
                <div>
                <h2>Family Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
            </div>
        </section>
        <Footer/>
    </React.Fragment>
  )
}

export default Service