
import React, { useState } from 'react'
const UpdateReview = ({data}) => {

//edit function
const updateDescription = async e =>{
    e.preventDefault();
    try{
        const body = {name, job, description};
        const response = await fetch(`http://localhost:5000/api/updatereview/${data.id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json"},
                body: JSON.stringify(body)
            }
        );
        window.location = "/dashboard/reviews";
        console.log(response);
    }
    catch (err){
        console.error(err.message);
    }
}

    console.log(data)
    const [name, setName] = useState(data.name);
    const [job, setJob] = useState(data.job);
    const [description, setDescription] = useState(data.description);
    // const [file, setFile] = useState(null);
  return (
    <React.Fragment>
        <button type="button" class="btn btn-warning" data-toggle="modal" data-target={`#id${data.id}`}>
  edit
</button>

{/* <!-- The Modal --> */}

<div class="modal" id={`id${data.id}`}>
  <div class="modal-dialog">
    <div class="modal-content">

      {/* <!-- Modal Header --> */}
      <div class="modal-header">
        <h4 class="modal-title">edit review</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      {/* <!-- Modal body --> */}
      <div class="modal-body">
        <input type='text' className='form-control' value={name} onChange={e => setName(e.target.value)}/>
        <input type='text' className='form-control' value={job} onChange={e => setJob(e.target.value)}/>
        <textarea type='text' className='form-control' value={description} onChange={e => setDescription(e.target.value)}/>
        {/* <input type='file' className='form-control' value={file} onChange={e => setFile(e.target.files[0])}/> */}
      </div>

      {/* <!-- Modal footer --> */}
      <div class="modal-footer">
      <button type="button" class="btn btn-warning" data-dismiss="modal" onClick={e => updateDescription(e)}>edit</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
    </React.Fragment>
    
  )
}

export default UpdateReview