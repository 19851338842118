import React from 'react'
import { FaFileImage, FaPeopleCarry, FaUserAltSlash } from 'react-icons/fa';
import '../style.css'
import Navbar from '../Navbar'
import Contact from './Contact'
import Footer from './Footer'
// import Service from './Service'

import HomeSite from './HomeSite'

import Revies from './Revies';




const Home = () => {
  return (
    <>
    
     <Navbar/>
 
     {/* <SlideImage/> */}
     <section class="home" >
       
    {/* <HomePage/> */}
    <HomeSite/>

    
   
    </section>
    {/* <br/><br/> */}
    <div className='home-container'>
    {/* <div class="status">
        <div class="box-container">
            <div class="box">
                <h2>20+</h2>
                <p>years in this field</p>
            </div>
            <div class="box">
                <h2>100+</h2>
                <p>legal issues for both small and large business</p>
            </div>
            <div class="box">
                <h2>98%</h2>
                <p>success rate in resolving favourable settlement for cliements</p>
            </div>
        </div>
    </div> */}
    {/* <ScrollToToButton/> */}
    <section class="about">
<div class="image">
    <img src ="images/teme.jpg"/>
</div>
<div class="content">
    <span>More about us</span>
    {/* <h1> More about us</h1> */}
    <p>Temesgen Sisay is the founding attorney at Temesgen Sisay Law office. He has been in the legal profession for over 17 years. He began his career by teaching laws at Bahir Dar University. He has been teaching various areas of law for 17 years and working as an Advocate at Temesgen Sisay Law Office since 2013. Temesgen  is serving legal needs of a wide portfolio of clients both local and international across a broad spectrum of industry sector.  He is highly committed and responsive to clients’ needs in attaining practical approaches to legal issues as well as effective legal solutions to the increasingly challenging and complex business environment 
Mr. Temesgen holds a law degree (LL.B.) from Haramaya University and Master’s Degree from Central European University, Hungary.  He has conducted several researches in various areas of Law.  Mr. Temesgen is a member of Ethiopian Bar Association and Amhara Region Bar Association
 </p>
     
     <div class="award">
        <div class="box">
            <h3>100+</h3>
            <p>happy clients</p>
        </div>
        <div class="box">
            <h3>10+</h3>
            <p>awards atetained</p>
        </div>
        <div class="box">
            <h3>50+</h3>
            <p>successful issues</p>
        </div>
     </div>  
     <a href="#" class="btn"> Learn more</a> 
</div>


    </section>
    <section className='service-section'>
        {/* <p>what we do</p> */}
        <h3>Service Sectors </h3>
            <div className='service'>
                <div className='service-content'>
                    <div><FaFileImage style={{ color: 'white', fontSize: '60px', }}/></div>
                    <div>
                    <h2> Contract</h2>
                    <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content'>
                <div><FaFileImage style={{ color: 'white', fontSize: '60px', }}/></div>
                <div>
                <h2>Agency Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content'>
                <div> <FaFileImage style={{ color: 'white', fontSize: '60px', }}/></div>
                <div>
                <h2>Banking and Insurance Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content'>
                 <div><FaFileImage style={{ color: 'white', fontSize: '60px', }}/></div>
                 <div>
                <h2> Corporate Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content'>
                <div><FaUserAltSlash style={{ color: 'white', fontSize: '60px', }}/></div>
                <div>
                <h2>Employment and Labour Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content'>
                <div><FaFileImage style={{ color: 'white', fontSize: '60px', }}/></div>
                <div>
                <h2>Arbitration Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
                <div className='service-content'>
                <div><FaPeopleCarry style={{ color: 'white', fontSize: '60px', }}/></div>
                <div>
                <h2>Family Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div>
            </div>
        </section>
          <Revies/>
   

</div>

        <Contact/>
    {/* <a href="#" class="gotop"> <img src="images/arrow-up.jpg" alt=""/></a> */}

    <Footer/>
    
</>
  
  )
 
}

export default Home
