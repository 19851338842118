import React from 'react'

const Maps = () => {
    return (
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1954.2687887388583!2d37.374481072305635!3d11.584971247116853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1644d20447abce49%3A0x903475d0a9850ab1!2sNoc%20Petrol!5e0!3m2!1sen!2set!4v1718864854738!5m2!1sen!2set"
              width="100%"
              height="450"
               style={{border: "0"}} 
               allowfullscreen="" 
               loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default Maps