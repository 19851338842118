import React, { useState } from 'react'
import Maps from './Maps'
import Contact from './Contact'
import Footer from './Footer'
import Navbar from '../Navbar'


const Contacts = () => {

  return (
    <React.Fragment>
        <div className=''>
        <Navbar/> 
        <h1 className='p-5 align-items-center'>Contac Us</h1> 
        <Contact/> 
        <Maps/>
        <Footer/>
        </div>

     
    </React.Fragment>
  )
}

export default Contacts