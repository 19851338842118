import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Users = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('http://localhost:5000/fetchuser');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching images', error);
      }
    };

    fetchUser();
  }, []);

  //delete
  const handleDelete =  (id) => {
    
    axios.delete('http://localhost:8081/delete/'+id)
   .then(res =>{
      // location.reload();
   })
   .catch(err => console.log(err))
    
  } 

  return (
    <div className='px-5 mt-3'>
    <div className='d-flex justify-content-center'>
        <h3>Review list</h3>
    </div>
    <Link to="/dashboard/signup" className='btn btn-success'> Add user</Link>
    <div className='mt-3'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Role</th>
                        <th>Action</th>
                      
                    </tr>
                </thead>
                <tbody>
                 
                {
                                data.map((user, index) =>(
                                    <tr key={index}>
                                    <td>{user.username}</td>
                                    <td>{user.role}</td>
                                   <td> <button onClick={() => handleDelete(user.contact_id)} className='btn btn-danger'>delete</button></td>
                                    
                                   
                                </tr>
                                ))
                            }
                   
                   
                 
                </tbody>
            </table>
    
    </div>
    </div>
  )
}

export default Users