// src/components/Register.js
import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/register', {
        username,
        password,
        role,
      });
      console.log('User registered:', response.data);
    } catch (err) {
      console.error('Error registering user:', err);
    }
  };

  return (
    <div className='d-flex justify-content-center align-items-center vh-75'>
    <div className='p-3 rounded w-25 border'>
        <div className='text-warning'>
           
        </div>
        <h2>add user</h2>
    <form onSubmit={handleSubmit}>
      <div className="d-flex mb-3 p-3 w-100">
        <label>Username:</label>
        <input  className='form-control'
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="d-flex mb-3 p-3 w-100">
        <label>Password:</label>
        <input  className='form-control'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="d-flex mb-3 p-3 w-100">
        <label>Role:</label>
        <input  className='form-control'
          type="text"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        />
      </div>
      <button type="submit" className='btn btn-success w-100'>Register</button>
    </form>
    </div>
    </div>
  );
};

export default Register;
