import React from 'react'
import Navbar from '../Navbar'
import Footer from './Footer'



const About = () => {
  return (
    <>
    <Navbar/>

     
  <center > <h1 ><span>____</span>About Us<span >____</span> </h1> 
          
          </center>
          <div class="status">
        {/* <div class="box-container">
            <div class="box">
                <h2>20+</h2>
                <p>years in this field</p>
            </div>
            <div class="box">
                <h2>100+</h2>
                <p>legal issues for both small and large business</p>
            </div>
            <div class="box">
                <h2>98%</h2>
                <p>success rate in resolving favourable settlement for cliements</p>
            </div>
        </div> */}
    </div>
    <section class="about">
<div class="image">
    <img src ="images/teme.jpg"/>
</div>
<div class="content">
    <span>More about us</span>
    {/* <h1> We take care of our clients</h1> */}
    <p>Temesgen Sisay is the founding attorney at Temesgen Sisay Law office. He has been in the legal profession for over 17 years. He began his career by teaching laws at Bahir Dar University. He has been teaching various areas of law for 17 years and working as an Advocate at Temesgen Sisay Law Office since 2013. Temesgen  is serving legal needs of a wide portfolio of clients both local and international across a broad spectrum of industry sector.  He is highly committed and responsive to clients’ needs in attaining practical approaches to legal issues as well as effective legal solutions to the increasingly challenging and complex business environment 
Mr. Temesgen holds a law degree (LL.B.) from Haramaya University and Master’s Degree from Central European University, Hungary.  He has conducted several researches in various areas of Law.  Mr. Temesgen is a member of Ethiopian Bar Association and Amhara Region Bar Association
 </p>
     
     <div class="award">
        <div class="box">
            <h3>100+</h3>
            <p>happy clients</p>
        </div>
        <div class="box">
            <h3>10+</h3>
            <p>awards atetained</p>
        </div>
        <div class="box">
            <h3>50+</h3>
            <p>successful issues</p>
        </div>
     </div>  
     
</div>
    </section>
    <section className='mission'>
            <div className="mission_vision">
            <div className="mision">
            <h2>Mision</h2>
           
                <p> <img src='images/missiom.png' />Our commitment is to assist our clients to achieve a more competitive
                     and profitable situation, applying the principles of organization, 
                     engineering, methodology and human resources. We develop and apply 
                     technologically innovating solutions to satisfy the customer's needs 
                     and goals.</p>
            </div>
            <div className="vision">
           
            <h2>Vision</h2>
                <p> <img src='images/vission.png' />Our vision is to position and consolidate our company as a leading supplier
                     of Software Engineering products and services at a national level.
                      With international projection, methodologies and standards, we use 
                      cutting-edge technologies that allow us to develop effective, reliable, 
                      scalable, and economic products that improve our costumers’ 
                      competitiveness and our own as well.</p>
            </div>
            </div>
        </section>
  <br></br>

  
  <Footer/>
 
    </>
  )
}

export default About
