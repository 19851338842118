import React from 'react'
import {  FaBalanceScale } from 'react-icons/fa';

const HomeSite = () => {
  return (
    <React.Fragment>
    <div className='homeSite'>
        <div className="homeSite-coll">
            <h1>Welcome to <br/> Temesgen Advocate</h1>
            <p>Temesgen has the ability to provide each client with individualized legal service that is tailored to meet their specific needs. Temesgen has been recognized in the legal community for his diversified areas of practice and legal experience, high quality legal work, practical approach and excellent results. He advises major companies including foreign owned companies operating in Ethiopia.
            </p>
        </div>
        <div className="homeSite-coll">
            <FaBalanceScale style={{ color: '#ec9f6b', fontSize: '400px' }}/>
        </div>
    </div>
    </React.Fragment>
  )
}

export default HomeSite