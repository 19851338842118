import axios from 'axios';
import React, { useEffect, useState } from 'react'

const NewsList = () => {
    const [data,  setData] = useState([]);
    useEffect(() =>{
        const fetchnews = async () =>{
          try{
            const response = await axios.get('http://localhost:5000/app/fetchnews');
           
            setData(response.data);
          } catch (error) {
              console.error('Error fetching data', error);
          }
        }
     
      fetchnews();
    }, []);
  return (
    <React.Fragment>
      <center>
        <div className='newslist'>
        {data.length > 0 ? (
        data.map(n => (
          <div  key={n.id}>
             <div className=" news">
                
                <img 
              src={`data:image/jpeg;base64,${n.data}`} 
              alt={n.name} 
            />
                    <div className="timezone">
                    <p>{n.date}</p>
                        <span>{n.title}</span>
                    </div>
                
                <p>
                {n.description}
                </p>
            </div>
           
           
          </div>
        )))
        : (
            <p>No images found.</p>
          )}
                
        </div>
        </center>
    </React.Fragment>
  )
}

export default NewsList